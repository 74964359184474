
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { mapStores } from "pinia"
import { MapType } from "@evercam/shared/types/map"
import GoogleMapsWrapper from "@/components/map/GoogleMapsWrapper"
import ProjectThumbnailEditDialog from "@/components/projects/ProjectThumbnailEditDialog"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent, AnalyticsEventPageId } from "@evercam/shared/types"

export default {
  meta: {
    pageId: AnalyticsEventPageId.ProjectOverview,
  },
  components: {
    GoogleMapsWrapper,
    ProjectThumbnailEditDialog,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbnail: null,
      projectThumbnailErrorPlaceholder: require("~/assets/img/waiting_for_activation.jpg"),
      mapType: MapType.Hybrid,
      showThumbnailEditDialog: false,
      thumbnailData: null,
    }
  },
  computed: {
    ...mapStores(useAccountStore, useProjectStore),
    projectMapViewUrl() {
      return `/v2/projects/${this.project.exid}/map`
    },
    projectLocation() {
      const totalCameras = this.projectStore.selectedProjectCameras.length

      if (totalCameras === 0) {
        return [
          {
            position: {
              lat: 0,
              lng: 0,
            },
          },
        ]
      }

      const centerLocation = this.projectStore.selectedProjectCameras.reduce(
        (acc, camera) => {
          if (camera.location) {
            acc.lat += camera.location.lat
            acc.lng += camera.location.lng
            acc.totalCameras++
          }

          return acc
        },
        { lat: 0, lng: 0, totalCameras: 0 }
      )

      return [
        {
          position: {
            lat: centerLocation.lat / centerLocation.totalCameras,
            lng: centerLocation.lng / centerLocation.totalCameras,
          },
        },
      ]
    },
    formattedProjectStartDate() {
      return this.$moment(this.project.startedAt).format("MMM DD, YYYY")
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    this.loadThumbnail()
  },
  methods: {
    ShowProjectThumbnailEditDialog() {
      this.showThumbnailEditDialog = true
    },
    async loadThumbnail() {
      this.thumbnailData = await this.getThumbnail()
      this.thumbnail = this.thumbnailData.image
    },
    async getThumbnail() {
      try {
        return await EvercamApi.projects.getThumbnailData(this.project.exid)
      } catch (error) {
        console.error("Error getting thumbnail origin", error)

        return {
          image: this.projectThumbnailErrorPlaceholder,
          origin: "error",
        }
      }
    },
  },
}
